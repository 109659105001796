import React, { ChangeEvent } from 'react';
import classes from '../CreateRecipeForm.module.css';

interface IProps {
    title: string;
    servings: number;
    changeInput: (event: ChangeEvent<HTMLInputElement>) => void;
    setValidity: (key: string, isValid: boolean) => void;
    invalidElementIds: string[];
}

/**
 * Generiert die Input-Gruppe für den Rezeptnamen und die Portionen-Anzahl
 */
const TitleAndServings = (props: IProps) => {
    function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
        switch (event.currentTarget.id) {
            case 'title':
                props.setValidity('title', event.currentTarget.value.trim().length > 5);
                break;
            case 'servings':
                props.setValidity('servings', Number(event.currentTarget.value) > 0);
                break;
        }
        props.changeInput(event);
    }
    const isTitleValid = props.invalidElementIds.indexOf('title') === -1;
    const isServingsValid = props.invalidElementIds.indexOf('servings') === -1;

    return (
        <div className={classes.fieldset}>
            <div
                className={[
                    classes.field,
                    classes.title,
                    isTitleValid ? null : classes.invalid,
                ].join(' ')}
            >
                <label>Rezeptname</label>
                <input
                    data-valid={isTitleValid}
                    id={'title'}
                    value={props.title}
                    type="text"
                    onChange={event => handleInputChange(event)}
                />
            </div>
            <div
                className={[
                    classes.field,
                    classes.servings,
                    isServingsValid ? null : classes.invalid,
                ].join(' ')}
            >
                <label>Portionen</label>
                <input
                    id={'servings'}
                    data-valid={isServingsValid}
                    value={props.servings}
                    type="number"
                    width="20%"
                    onChange={event => handleInputChange(event)}
                />
            </div>
        </div>
    );
};

export default TitleAndServings;
