import React, { ChangeEvent } from 'react';
import classes from '../../CreateRecipeForm.module.css';

interface IProps {
    instrId: number;
    instructions: string[];
    changeInstruction: (event: ChangeEvent<HTMLTextAreaElement>, instrId: number) => void;
}
/**
 * Generiert Texteingabefeld für einen einzelnen Zubereitungsschritt
 */
const IngredientInput = (props: IProps) => (
    <div className={[classes.fieldset, classes.row].join(' ')}>
        <div className={[classes.field, classes.instructions].join(' ')}>
            <label>Schritt {props.instrId + 1}</label>
            <textarea
                id={'instructions'}
                value={props.instructions[props.instrId]}
                onChange={event => props.changeInstruction(event, props.instrId)}
                rows={2}
            />
        </div>
    </div>
);

export default IngredientInput;
