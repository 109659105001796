import React from 'react';
import classes from './Spinner.module.css';
// Spinner entnommen von https://loading.io/css/ (unter CC0-Lizenz)

const Spinner = () => (
    <div className={classes.spinner}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
    </div>
);

export default Spinner;
