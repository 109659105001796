import React from 'react';
import classes from '../ViewRecipePage.module.css';

import { IIngredient, IRecipe } from '../../../shared/interfaces';

import Button from '../../../components/UI/Button/Button';
import IngredientList from '../IngredientList/IngredientList';
import InstructionList from '../InstructionList/InstructionList';

import placeholderImage from '../../../img/placeholder-image.png';

interface IProps {
    recipe: IRecipe;
    editRecipe: () => void;
    deleteRecipe: () => void;
}

const PageContentView = (props: IProps) => (
    <div className={classes.container}>
        <RecipeHeader {...props} />
        <IngredientsContainer ingredients={props.recipe.ingredients} />
        <InstructionsContainer instructions={props.recipe.instructions} />
    </div>
);

export default PageContentView;

/**
 * Erzeugt die Rezeptübersicht
 * (Titel, Bild, Löschen- u. Bearbeiten-Button sowie zusätzliche Rezeptinformationen)
 */
function RecipeHeader(props: IProps) {
    return (
        <div className={classes.overview}>
            <div className={[classes.recipeInfo, classes.textContainer].join(' ')}>
                <h1>{props.recipe.title}</h1>
                <div className={classes.prepInfo}>
                    <p className={classes.label}>
                        Portionen:{' '}
                        <strong>
                            {props.recipe.servings !== 0 ? props.recipe.servings : 'keine Angabe'}
                        </strong>
                    </p>
                    <p className={classes.label}>
                        Schwierigkeitsgrad:{' '}
                        <strong>
                            {props.recipe.difficulty ? props.recipe.difficulty : 'keine Angabe'}
                        </strong>
                    </p>
                    <p className={classes.label}>
                        Zubereitungsdauer:{' '}
                        <strong>
                            {props.recipe.durationMinutes !== 0
                                ? (props.recipe.durationHours !== 0
                                      ? props.recipe.durationHours + ' h '
                                      : '') +
                                  props.recipe.durationMinutes +
                                  ' min'
                                : 'keine Angabe'}
                        </strong>
                    </p>
                    <p className={classes.label}>
                        Kalorien pro Portion:{' '}
                        <strong>
                            {props.recipe.calories !== 0
                                ? props.recipe.calories + ' kcal'
                                : 'keine Angabe'}
                        </strong>
                    </p>
                </div>
                <div className={classes.Buttons}>
                    <Button buttonType="link" clicked={props.editRecipe}>
                        Bearbeiten
                    </Button>
                    <Button buttonType="link-secondary" clicked={props.deleteRecipe}>
                        Löschen
                    </Button>
                </div>
            </div>{' '}
            {/*.recipeInfo */}
            <div className={classes.recipeImage}>
                <img src={props.recipe.image || placeholderImage} alt={props.recipe.title} />
            </div>
        </div>
    );
}

interface IPropsIngredients {
    ingredients: IIngredient[];
}

/**
 * Erzeugt die Zutatenübersicht
 */
function IngredientsContainer(props: IPropsIngredients) {
    return (
        <div className={classes.ingredientsContainer}>
            <div className={[classes.ingredients, classes.textContainer].join(' ')}>
                <h3>Zutaten:</h3>
                <div className={classes.ingredientsList}>
                    <ul>
                        <IngredientList ingredients={props.ingredients} />
                    </ul>
                </div>
            </div>
        </div>
    );
}

interface IPropsInstructions {
    instructions: string[];
}
/**
 * Erzeugt die Zubereitungshinweise
 */
function InstructionsContainer(props: IPropsInstructions) {
    return (
        <div className={classes.instructionsContainer}>
            <div className={[classes.instructions, classes.textContainer].join(' ')}>
                <h3>Zubereitung:</h3>
                <ol>
                    <InstructionList instructions={props.instructions} />
                </ol>
            </div>
        </div>
    );
}
