import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import classes from './App.module.css';

import Layout from './components/Layout/Layout';
import AllRecipesPage from './containers/AllRecipesPage/AllRecipesPage';
import CreateRecipePage from './containers/CreateRecipePage/CreateRecipePage';
import EditRecipePage from './containers/EditRecipePage/EditRecipePage';
import SearchRecipesPage from './containers/SearchRecipesPage/SearchRecipesPage';
import ViewRecipePage from './containers/ViewRecipePage/ViewRecipePage';
import LoginPage from "./containers/LoginPage/LoginPage";
import QuickAddPage from "./containers/QuickAddPage/QuickAddPage";

class App extends Component {
    public render() {
        return (
            <BrowserRouter>
                <div className={classes.app}>
                    <Layout>
                        <Switch>
                            <Route path="/create" component={CreateRecipePage} />
                            <Route path="/view" component={ViewRecipePage} />
                            <Route path="/edit" component={EditRecipePage} />
                            <Route path="/search" component={SearchRecipesPage} />
                            <Route path="/login" component={LoginPage} />
                            <Route path="/qa" component={QuickAddPage} />
                            <Route path="/" component={AllRecipesPage} />
                        </Switch>
                    </Layout>
                </div>
            </BrowserRouter>
        );
    }
}

export default App;
