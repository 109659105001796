import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classes from '../../shared/CreateRecipeForm/CreateRecipeForm.module.css';

import CreateRecipeForm from '../../shared/CreateRecipeForm/CreateRecipeForm';

class EditRecipePage extends Component<RouteComponentProps> {
    private getSearchParamID = () => {
        const query = new URLSearchParams(this.props.location.search);
        return query.get('id');
    };

    public render() {
        return (
            <div className={classes.createRecipeForm}>
                <h2>Rezept bearbeiten</h2>
                <CreateRecipeForm recipeID={Number(this.getSearchParamID())} mode="edit" />
            </div>
        );
    }
}

export default EditRecipePage;
