import React, { ChangeEvent } from 'react';
import classes from '../CreateRecipeForm.module.css';

interface IProps {
    image: string;
    uploadImage: (event: ChangeEvent<HTMLInputElement>) => void;
}

/**
 * Generiert Eingabefeld für das Rezeptbild
 * (ggf. bestehende Bilddatei wird ebenfalls angezeigt)
 */
const RecipeImage = (props: IProps) => {
    let previousImage = null;
    if (props.image) {
        previousImage = <img width="200" src={props.image} alt="Rezeptbild" />;
    }

    return (
        <div className={[classes.fieldset, classes.imageBox].join(' ')}>
            {previousImage}
            <div className={[classes.field, classes.recipeImage].join(' ')}>
                <label>Rezeptbild hochladen</label>
                <input id={'image'} type="file" onChange={event => props.uploadImage(event)} />
            </div>
        </div>
    );
};

export default RecipeImage;
