import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classes from './DeleteHandling.module.css';

import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';

interface IProps extends RouteComponentProps {
    id?: number;
    deleted?: () => void;
    cancel: () => void;
}

interface IState {
    deleted: boolean;
    error: boolean;
}
/**
 * Stellt die Löschfunktion für Rezepte auf den verschiedenen Seiten zur Verfügung
 */
class DeleteHandling extends Component<IProps, IState> {
    public state: IState = {
        deleted: false,
        error: false,
    };

    private deleteRecipeHandler = () => {
        const id = this.props.id;
        const authKey = localStorage.getItem('authKey');
        fetch('https://recipes.pyfox.net/php/delete.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id, authKey }),
        })
            .then(response => response.text())
            .then(() => {
                this.setState({ deleted: true });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    };

    private redirectAfterDelete = () => {
        this.setState({ deleted: false });
        this.props.history.replace('/');
        if (this.props.deleted !== undefined) {
            this.props.deleted();
        }
    };

    public render() {
        return (
            <div className={classes.deleteHandling}>
                {/* Mit Aufruf der Methode wird der Nutzer per Modal zur Bestätigung aufgefordert */}
                <Modal
                    show={!!this.props.id && !this.state.deleted}
                    modalClosed={this.props.cancel}
                >
                    <div>
                        <p>Soll das Rezept wirklich gelöscht werden?</p>
                        <div className={classes.buttonLine}>
                            <Button buttonType="primary" clicked={this.deleteRecipeHandler}>
                                Löschen
                            </Button>
                            <Button buttonType="secondary" clicked={this.props.cancel}>
                                Abbrechen
                            </Button>
                        </div>
                    </div>
                </Modal>

                {/* Bestätigung an den Nutzer, dass das Rezept gelöscht wurde */}
                <Modal show={this.state.deleted} modalClosed={this.redirectAfterDelete}>
                    <div>
                        <p>Rezept gelöscht</p>
                        <Button buttonType="secondary" clicked={this.redirectAfterDelete}>
                            Ok
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}

export default withRouter(DeleteHandling);
