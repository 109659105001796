import React, { Fragment, ReactNode } from 'react';
import classes from '../../AllRecipesPage/AllRecipesPage.module.css';

interface IProps {
    searchParams: string;
    recipeList: ReactNode[] | ReactNode;
}
const PageContentSearch = (props: IProps) => (
    <Fragment>
        <h1>Rezepte mit: {props.searchParams.replace(/ /g, ', ')}</h1>
        <table className={classes.recipeListTable}>
            <tbody>{props.recipeList}</tbody>
        </table>
    </Fragment>
);

export default PageContentSearch;
