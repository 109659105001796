import React from 'react';
import classes from './Toolbar.module.css';

import NavigationItems from '../NavigationItems/NavigationItems';
import Searchbar from '../Searchbar/Searchbar';

interface IProps {
    windowWidth: number;
}

const Toolbar = (props: IProps) => (
    <header className={classes.toolbar}>
        <nav>
            <NavigationItems windowWidth={props.windowWidth} />
        </nav>
        <div className={classes.searchbar}>
            <Searchbar />
        </div>
    </header>
);

export default Toolbar;
