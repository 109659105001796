import React, { MouseEvent } from 'react';
import classes from './Button.module.css';

interface IProps {
    buttonType: 'primary' | 'secondary' | 'link' | 'link-secondary';
    clicked: (event: MouseEvent) => void;
    disabled?: boolean;
    className?: string;
    children: string;
}

const Button = (props: IProps) => (
    <button
        className={[classes.button, classes[props.buttonType]].join(' ')}
        onClick={props.clicked}
        disabled={props.disabled}
    >
        {props.children}
    </button>
);

export default Button;
