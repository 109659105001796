import React from 'react';
import NavigationItem from './NavigationItem/NavigationItem';
import classes from './NavigationItems.module.css';

interface IProps {
    windowWidth: number;
}
const NavigationItems = (props: IProps) => (
    <ul className={classes.navigationItems}>
        <NavigationItem link="/" exact>
            {props.windowWidth < 768 ? <i className="fas fa-list" /> : 'Rezepte'}
        </NavigationItem>
        <NavigationItem link="/create">
            {props.windowWidth < 768 ? <i className="fas fa-plus" /> : 'Neues Rezept'}
        </NavigationItem>
    </ul>
);

export default NavigationItems;
