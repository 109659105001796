import React, { Component, Fragment, ReactNode } from 'react';
import classes from './Layout.module.css';

import Toolbar from '../Toolbar/Toolbar';

interface IProps {
    children: ReactNode;
}

interface IState {
    windowInnerWidth: number;
}

class Layout extends Component<IProps, IState> {
    public state: IState = {
        windowInnerWidth: 0,
    };
    public componentDidMount() {
        this.setState({ windowInnerWidth: window.innerWidth });
        window.addEventListener('resize', this.windowWidthListener);
    }

    private windowWidthListener = () => {
        this.setState({ windowInnerWidth: window.innerWidth });
    };

    public render() {
        return (
            <Fragment>
                <div className={classes.background} />
                <Toolbar windowWidth={this.state.windowInnerWidth} />
                <main className={classes.content}>
                    <div className={classes.container}>{this.props.children}</div>
                </main>
            </Fragment>
        );
    }
}

export default Layout;
