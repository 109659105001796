import React, { ChangeEvent } from 'react';
import classes from '../../CreateRecipeForm.module.css';

import { IIngredient } from '../../../interfaces';

interface IProps {
    ingId: number;
    changeIngredient: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        ingId: number,
    ) => void;
    ingredient: IIngredient;
}
/**
 * Generiert Input-Gruppe für eine Zutat nebst Menge und Einheit
 */
const IngredientInput = (props: IProps) => (
    <div className={[classes.fieldset, classes.row, classes.ingredientRow].join(' ')}>
        <div className={[classes.field, classes.ingAmount].join(' ')}>
            <label>{props.ingId === 0 ? 'Menge' : null}</label>
            <input
                id={'ingAmount'}
                type="number"
                value={props.ingredient.amount}
                onChange={event => props.changeIngredient(event, props.ingId)}
            />
        </div>
        <div className={[classes.field, classes.ingUnit].join(' ')}>
            <label>{props.ingId === 0 ? 'Einheit' : null}</label>
            <select
                id={'ingUnit'}
                value={props.ingredient.unit}
                onChange={event => props.changeIngredient(event, props.ingId)}
            >
                <option value="g">g</option>
                <option value="ml">ml</option>
                <option value="l">l</option>
                <option value="Prise(n)">Prise(n)</option>
                <option value="Stück">Stück</option>
                <option value="Dose(n)">Dose(n)</option>
                <option value="EL">EL</option>
                <option value="TL">TL</option>
                <option value="etwas">etwas</option>
                <option value="Bund">Bund</option>
                <option value="Zehe(n)">Zehe(n)</option>
                <option value="cm">cm</option>
            </select>
        </div>
        <div className={[classes.field, classes.ingName].join(' ')}>
            <label>{props.ingId === 0 ? 'Zutatenname' : null}</label>
            <input
                id={'ingName'}
                type="text"
                value={props.ingredient.name}
                onChange={event => props.changeIngredient(event, props.ingId)}
            />
        </div>
    </div>
);

export default IngredientInput;
