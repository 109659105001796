import React, { ChangeEvent, Component, Fragment, MouseEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { replaceAll } from '../helpers';
import classes from './CreateRecipeForm.module.css';

import { IIngredient, IRecipe } from '../interfaces';

import Button from '../../components/UI/Button/Button';
import Modal from '../../components/UI/Modal/Modal';
import Spinner from '../../components/UI/Spinner/Spinner';
import IngredientInputs from './IngredientInputs/IngredientInputs';
import InstructionInputs from './InstructionInputs/InstructionInputs';
import PreparationInfos from './PreparationInfos/PreparationInfos';
import RecipeImage from './RecipeImage/RecipeImage';
import TitleAndServings from './TitleAndServings/TitleAndServings';

interface IProps extends RouteComponentProps {
    recipeID?: number | null;
    mode: 'create' | 'edit';
}

interface INewRecipeData {
    [key: string]: string | number | null;
    title: string;
    servings: number;
    durationHours: number | null;
    durationMinutes: number | null;
    difficulty: 'leicht' | 'mittel' | 'schwer';
    calories: number | null;
}

interface IState {
    newRecipeData: INewRecipeData;
    instructions: string[];
    ingredients: IIngredient[];
    uploadedImage: string;
    loading: boolean;
    submitted: boolean;
    error: boolean;
    id: number;
    invalidItems: string[];
    formDisabled: boolean;
}

class CreateRecipeForm extends Component<IProps, IState> {
    public state: IState = {
        newRecipeData: {
            title: '',
            servings: 1,
            durationHours: null,
            durationMinutes: null,
            difficulty: 'leicht',
            calories: null,
        },
        instructions: [''], // 3 Schritte: ['','','']
        ingredients: [
            { name: '', unit: 'g', amount: '' },
            { name: '', unit: 'g', amount: '' },
            { name: '', unit: 'g', amount: '' },
        ],
        uploadedImage: '',
        loading: false,
        submitted: false,
        error: false,
        id: 0,
        invalidItems: [],
        formDisabled: this.props.mode === 'create' ? true : false,
    };

    public componentDidMount() {
        if (!this.props.recipeID) {
            return;
        }

        this.setState({ loading: true });
        const id = this.props.recipeID;
        const authKey = localStorage.getItem('authKey');
        fetch('https://recipes.pyfox.net/php/getRecipe.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id, authKey }),
        })
            .then(response => response.json())
            .then(data => {
                const ingredients = JSON.parse(data.ingredients);
                const instructions = JSON.parse(replaceAll(data.instructions, '\n', '\\n'));
                const newRecipeData = {
                    title: data.title,
                    servings: data.servings,
                    durationHours: data.durationHours,
                    durationMinutes: data.durationMinutes,
                    difficulty: data.difficulty,
                    calories: data.calories,
                };
                const uploadedImage = data.image;
                this.setState({
                    ingredients,
                    instructions,
                    newRecipeData,
                    uploadedImage,
                    loading: false,
                });
            })
            .catch(() => this.setState({ error: true }));
    }

    public componentDidUpdate() {
        if (!this.props.recipeID) {
            return;
        }

        if (this.state.submitted) {
            const id = this.props.recipeID;
            this.props.history.push('/view?id=' + id);
        }
    }

    /**
     * Markiert ein Input-Feld als gültig / ungültig
     * und (de-)aktiviert damit den Speicher-Button des Formulares
     */
    private setValidity = (elementId: string, isValid: boolean) => {
        this.setState((oldState: IState) => {
            const invalidItems = [...oldState.invalidItems];
            const indexToRemove = invalidItems.indexOf(elementId);
            let formIsInvalid = true;
            if (isValid && indexToRemove >= 0) {
                invalidItems.splice(indexToRemove, 1);
                if (invalidItems.length === 0) {
                    formIsInvalid = false;
                }
            } else if (!isValid && indexToRemove === -1) {
                invalidItems.push(elementId);
            } else {
                return null;
            }
            return { invalidItems, formDisabled: formIsInvalid };
        });
    };

    private changeInputHandler = (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const updatedRecipeData: INewRecipeData = {
            ...this.state.newRecipeData,
        };
        updatedRecipeData[event.target.id as keyof INewRecipeData] = event.target.value;

        this.setState({ newRecipeData: updatedRecipeData });
    };

    private changeIngredientHandler = (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        index: number,
    ) => {
        const updatedIngredientArray = [...this.state.ingredients];
        const updatedIngredientObject = {
            ...updatedIngredientArray[index],
        };

        switch (event.target.id) {
            case 'ingAmount':
                updatedIngredientObject.amount = Number(event.target.value);
                break;
            case 'ingUnit':
                updatedIngredientObject.unit = event.target.value;
                break;
            case 'ingName':
                updatedIngredientObject.name = event.target.value;
                break;
            default:
                break;
        }
        updatedIngredientArray[index] = updatedIngredientObject;
        this.setState({ ingredients: updatedIngredientArray });
    };

    private changeInstructionHandler = (event: ChangeEvent<HTMLTextAreaElement>, index: number) => {
        const updatedInstructionArray = [...this.state.instructions];
        updatedInstructionArray[index] = event.target.value;
        this.setState({ instructions: updatedInstructionArray });
    };

    private addIngredientsHandler = (event: MouseEvent) => {
        event.preventDefault();
        this.setState(oldState => {
            const updatedIngredientArray = [...oldState.ingredients];
            updatedIngredientArray.push({ name: '', amount: '', unit: 'g' });
            updatedIngredientArray.push({ name: '', amount: '', unit: 'g' });
            updatedIngredientArray.push({ name: '', amount: '', unit: 'g' });
            return {
                ingredients: updatedIngredientArray,
            };
        });
    };

    private addInstructionsHandler = (event: MouseEvent) => {
        event.preventDefault();
        this.setState(oldState => {
            const updatedInstructionsArray = [...oldState.instructions];
            updatedInstructionsArray.push('');
            return {
                instructions: updatedInstructionsArray,
            };
        });
    };

    private uploadImageHandler = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }
        this.getBase64(event.target.files[0]);
    };

    /**
     * Kodiert Bilddatei in eindeutiges String-Format,
     * in welchem es in der Datenbank hinterlegt wird
     */
    private getBase64 = (file: File) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.setState({ uploadedImage: reader.result as string });
        };
        reader.onerror = error => {
            console.log('Error: ', error);
        };
    };

    /**
     * Filtert leere Zutatenzeilen heraus, sodass diese nicht an die DB übergeben werden
     */
    private getFilledIngredients = () => {
        const filledArrayElements: IIngredient[] = [];
        this.state.ingredients.forEach(ingredient => {
            if (ingredient.amount !== 0 && ingredient.name !== '') {
                filledArrayElements.push(ingredient);
            }
        });
        return filledArrayElements;
    };

    /**
     * Filtert leere Zubereitungsschritt-Inputs heraus,
     * sodass diese nicht an die DB übergeben werden
     */
    private getFilledInstructions = () => {
        const filledArrayElements: string[] = [];
        this.state.instructions.forEach(instruction => {
            if (instruction.trim().length !== 0) {
                filledArrayElements.push(instruction);
            }
        });
        return filledArrayElements;
    };

    private submitRecipeFormHandler = (event: MouseEvent) => {
        event.preventDefault();
        this.setState({ loading: true });

        let url = 'https://recipes.pyfox.net/php/add.php';

        const recipeData: IRecipe = {
            ...this.state.newRecipeData,
            ingredients: this.getFilledIngredients(),
            instructions: this.getFilledInstructions(),
            image: this.state.uploadedImage,
            recipeID: -1,
        };

        if (this.props.recipeID) {
            url = 'https://recipes.pyfox.net/php/edit.php';
            recipeData.recipeID = this.props.recipeID;
        }
        // @ts-ignore
        recipeData.authKey = localStorage.getItem('authKey');
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(recipeData),
        })
            .then(response => response.text())
            .then(data => {
                this.setState({ submitted: true, loading: false, id: Number(data) });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    };

    public render() {
        if (this.state.loading) {
            return <Spinner />;
        }

        return (
            <Fragment>
                <Modal
                    show={this.state.submitted}
                    modalClosed={() => this.props.history.replace('/view?id=' + this.state.id)}
                >
                    <p>Rezept wurde erstellt</p>
                    <Button
                        buttonType="secondary"
                        clicked={() => this.props.history.replace('/view?id=' + this.state.id)}
                    >
                        Ok
                    </Button>
                </Modal>

                <form className={classes.Form}>
                    <TitleAndServings
                        title={this.state.newRecipeData.title}
                        servings={this.state.newRecipeData.servings}
                        changeInput={this.changeInputHandler}
                        setValidity={this.setValidity}
                        invalidElementIds={this.state.invalidItems}
                    />
                    <h3>Zutaten</h3>
                    <IngredientInputs
                        ingredients={this.state.ingredients}
                        changeIngredient={this.changeIngredientHandler}
                        ingredientFieldsCounter={this.state.ingredients.length}
                    />
                    <Button buttonType="secondary" clicked={this.addIngredientsHandler}>
                        +
                    </Button>
                    <h3>Zubereitung</h3>
                    <InstructionInputs
                        instructions={this.state.instructions}
                        changeInstruction={this.changeInstructionHandler}
                        instructionFieldsCounter={this.state.instructions.length}
                    />
                    <Button buttonType="secondary" clicked={this.addInstructionsHandler}>
                        +
                    </Button>
                    <h3>Zusatzinformationen</h3>
                    <PreparationInfos
                        durationHours={this.state.newRecipeData.durationHours}
                        durationMinutes={this.state.newRecipeData.durationMinutes}
                        difficulty={this.state.newRecipeData.difficulty}
                        calories={this.state.newRecipeData.calories}
                        changeInput={this.changeInputHandler}
                    />
                    <RecipeImage
                        image={this.state.uploadedImage}
                        uploadImage={event => this.uploadImageHandler(event)}
                    />
                    <Button
                        className={classes.save}
                        buttonType="primary"
                        clicked={this.submitRecipeFormHandler}
                        disabled={this.state.formDisabled}
                    >
                        Speichern
                    </Button>
                </form>
            </Fragment>
        );
    }
}

export default withRouter(CreateRecipeForm);
