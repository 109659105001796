import React from 'react';
import classes from './Backdrop.module.css';

interface IProps {
    show: boolean;
    clicked: () => void;
}

/**
 * Klickbarer Hintergrund, der den Nutzer u. a. bei Modals zurück auf die Hauptseite führt
 */
const Backdrop = (props: IProps) =>
    props.show ? <div className={classes.backdrop} onClick={props.clicked} /> : null;

export default Backdrop;
