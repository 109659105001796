import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router';
import classes from './ViewRecipePage.module.css';

import { IRecipe } from '../../shared/interfaces';

import Spinner from '../../components/UI/Spinner/Spinner';
import DeleteHandling from '../../shared/DeleteHandling/DeleteHandling';
import { replaceAll } from '../../shared/helpers';
import PageContentView from './PageContentView/PageContentView';

interface IState {
    recipe?: IRecipe;
    error: boolean;
    itemIdToDelete?: number;
}

class ViewRecipePage extends Component<RouteComponentProps, IState> {
    public state: IState = {
        recipe: undefined,
        error: false,
        itemIdToDelete: undefined,
    };

    public componentDidMount() {
        const id = this.extractSearchParamID();
        const authKey = localStorage.getItem('authKey');
        fetch('https://recipes.pyfox.net/php/getRecipe.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ id, authKey }),
        })
            .then(response => response.json())
            .then(data => {
                const instructions = replaceAll(data.instructions, '\n', '\\n');
                data.ingredients = JSON.parse(data.ingredients);
                data.instructions = JSON.parse(instructions);
                data.calories = Number(data.calories);
                data.durationMinutes = Number(data.durationMinutes);
                data.durationHours = Number(data.durationHours);
                data.servings = Number(data.servings);
                this.setState({ recipe: data });
            })
            .catch(error => {
                console.log(error);
                this.setState({ error: true });
            });
    }

    private extractSearchParamID = (): number | undefined => {
        const query = new URLSearchParams(this.props.location.search);
        return Number(query.get('id')) || undefined;
    };

    private editRecipeHandler = () => {
        const id = this.extractSearchParamID();
        this.props.history.push('/edit?id=' + id);
    };

    private initDeleteHandler = () => {
        const id = this.extractSearchParamID();
        this.setState({ itemIdToDelete: id });
    };

    private cancelDeleteHandler = () => {
        this.setState({ itemIdToDelete: undefined });
    };

    public render() {
        return (
            <div className={classes.container}>
                {this.state.error ? <div> Da ist leider etwas schief gelaufen..</div> : null}
                <DeleteHandling id={this.state.itemIdToDelete} cancel={this.cancelDeleteHandler} />

                {this.state.recipe ? (
                    <PageContentView
                        recipe={this.state.recipe}
                        editRecipe={this.editRecipeHandler}
                        deleteRecipe={this.initDeleteHandler}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }
}

export default ViewRecipePage;
