import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Spinner from '../../components/UI/Spinner/Spinner';
import DeleteHandling from '../../shared/DeleteHandling/DeleteHandling';
import { IRecipeInfo } from '../../shared/interfaces';
import classes from './AllRecipesPage.module.css';
import ListEntry from './ListEntry/ListEntry';
import PageContentAll from './PageContentAll/PageContentAll';

interface IState {
    recipesList: IRecipeInfo[];
    itemIdToDelete?: number;
}

class AllRecipesPage extends Component<RouteComponentProps, IState> {
    public state = {
        recipesList: [],
        itemIdToDelete: undefined,
    };

    public componentDidMount() {
        this.fetchRecipes();
    }

    /**
     * Ruft alle verfügbaren Rezepte aus der Datenbank ab
     */
    private fetchRecipes = () => {
        const authKey = localStorage.getItem('authKey');
        fetch('https://recipes.pyfox.net/php/getList.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ authKey }),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ recipesList: data });
            });
    };

    private editRecipeHandler = (id: number) => {
        this.props.history.push('/edit?id=' + id);
    };

    /**
     * Leitet Löschvorgang ein, der durch Nutzer bestätigt wird,
     * ehe der Eintrag aus der DB entfernt wird
     */
    private initDeleteHandler = (id: number) => {
        this.setState({ itemIdToDelete: id });
    };

    private cancelDeleteHandler = () => {
        this.setState({ itemIdToDelete: undefined });
    };

    private deletedRecipeHandler = () => {
        this.setState({ itemIdToDelete: undefined });
        this.fetchRecipes();
    };

    public render() {
        if (this.state.recipesList === []) {
            return;
        }
        // GUI-Komponenten werden aus DB-Einträgen generiert
        const recipesArray = [];
        for (const key in this.state.recipesList) {
            const recipe: IRecipeInfo = this.state.recipesList[key];
            recipesArray.push(
                <ListEntry
                    key={recipe.recipeID}
                    recipeInfo={recipe}
                    editRecipe={() => this.editRecipeHandler(recipe.recipeID)}
                    deleteRecipe={() => this.initDeleteHandler(recipe.recipeID)}
                />,
            );
        }
        return (
            <div className={classes.allRecipes}>
                <DeleteHandling
                    id={this.state.itemIdToDelete}
                    cancel={this.cancelDeleteHandler}
                    deleted={this.deletedRecipeHandler}
                />
                {this.state.recipesList.length !== 0 ? (
                    <PageContentAll recipeList={recipesArray} />
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }
}

export default AllRecipesPage;
