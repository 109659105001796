import React, { Component, Fragment, ReactNode } from 'react';
import classes from './Modal.module.css';

import Backdrop from '../Backdrop/Backdrop';

interface IProps {
    show: boolean;
    modalClosed: () => void;
    children: ReactNode;
}

class Modal extends Component<IProps> {
    public shouldComponentUpdate(nextProps: IProps) {
        return (
            nextProps.show !== this.props.show ||
            (this.props.show && nextProps.children !== this.props.children)
        );
    }

    public render() {
        const modalClasses = [classes.modal, this.props.show ? classes.show : null]
            .filter(Boolean)
            .join(' ');
        return (
            <Fragment>
                <Backdrop show={this.props.show} clicked={this.props.modalClosed} />
                <div className={modalClasses}>{this.props.children}</div>
            </Fragment>
        );
    }
}

export default Modal;
