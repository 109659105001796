/**
 * Ersetzt alle Vorkomnisse des "search"-Strings im "oldString"
 * durch das gewünschte "replacement"
 */
export const replaceAll = (oldString: string, search: string, replacement: string): string =>
    oldString.split(search).join(replacement);

export function generateHash(s: string): string {
    return String(s.split("").reduce(function(a,b){a=((a<<5)-a)+b.charCodeAt(0);return a&a},0));
}
