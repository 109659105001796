import React, { FormEvent } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import classes from './Searchbar.module.css';

const Searchbar = (props: RouteComponentProps) => {
    function submitSearchParamsHandler(event: FormEvent<HTMLFormElement>) {
        event.preventDefault();
        props.history.push(
            '/search?term=' +
                (event.currentTarget.elements.namedItem('search') as HTMLInputElement).value,
        );
    }

    return (
        <form onSubmit={submitSearchParamsHandler}>
            <input className={classes.searchbar} id="search" type="text" placeholder="Suche" />
        </form>
    );
};

export default withRouter(Searchbar);
