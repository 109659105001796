import React, { ChangeEvent, Component, MouseEvent } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classes from '../../shared/CreateRecipeForm/CreateRecipeForm.module.css';
import Button from '../../components/UI/Button/Button';
import { IIngredient, IRecipe } from '../../shared/interfaces';

const defaultState = {
    title: '',
    content: '',
    servings: 2,
    id: 0,
    submitted: false,
    error: '',
};

// Falls ein String aussieht wie eine Zutat, aber eines dieser Wörter darin vorkommt, dann wird
// die entsprechende Zeile ignoriert.
const INGREDIENT_BLACKLIST = ['Zutaten', ':'];

const INGREDIENT_ALIAS = [
    ['Prise(n)', 'Prise'],
    ['Stückchen', 'Stück'],
    ['Zehe(n)', 'Zehe'],
    ['½', '0.5'],
    ['Kopf', 'Stück'],
    ['Konserve', 'Dose'],
    ['Konserve(n)', 'Dose'],
    ['Stange', 'Stück'],
    ['Stange(n)', 'Stück'],
];

const UNITS = [
    'g',
    'ml',
    'l',
    'prise',
    'prisen',
    'stück',
    'dose',
    'dosen',
    'el',
    'tl',
    'etwas',
    'bund',
    'zehe',
    'zehen',
    'cm',
    'kg',
];

class QuickAddPage extends Component<RouteComponentProps, typeof defaultState> {
    public readonly state: typeof defaultState = defaultState;

    /**
     * Extrahiert die Zutaten aus dem String
     * Beispiel für Zutatenformatierte Zeilen:
     * 3 Äpfel
     * etwas Zimt
     * etwas Eiscreme, Optional
     */
    extractIngredients = (): IIngredient[] => {
        let text = this.state.content.split(/\n/);
        let ingredients: IIngredient[] = [];

        // Eine einzelne Zeile darf keine Zutat sein, bspw. bei Zwischenüberschriften wie
        // "Für die Soße". Sollten mehrere Zeilen nacheinander nicht aussehen wie eine Zutat,
        // dann wird davon ausgegangen, dass die Zutaten-Sektion verlassen wurde und nun das
        // restliche Rezept angezeigt wird.
        let previousLineWasIngredient = 0;
        for (let line of text) {
            if (!line) {
                previousLineWasIngredient -= 1;
                continue;
            }
            for (let [alias, replacement] of INGREDIENT_ALIAS) {
                line = line.replace(alias, replacement);
            }
            let continueOuter = false;
            for (let word of INGREDIENT_BLACKLIST) {
                if (line.includes(word)) {
                    continueOuter = true;
                    break;
                }
            }
            if (continueOuter) {
                continue;
            }
            let l = line.split(/\s/);
            if (l.length < 2 || l.length > 5) {
                previousLineWasIngredient -= 1;
                continue;
            }
            if (ingredients.length > 0 && previousLineWasIngredient === 0) {
                break;
            }
            let nameParts = [];
            let amount = 1;
            let unit = 'stück';
            for (let element of l) {
                if (UNITS.includes(element.toLowerCase())) {
                    unit = element;
                    continue;
                }
                if (!isNaN(Number(element))) {
                    amount = Number(element);
                    continue;
                }
                nameParts.push(element);
            }
            let name = nameParts.join(' ');
            previousLineWasIngredient = 2;
            ingredients.push({
                name: name,
                amount: amount,
                unit: unit,
            });
        }
        return ingredients;
    };

    /**
     * Extrahiert die Schritte aus dem String.
     */
    extractSteps = (): string[] => {
        let text = this.state.content.split(/\n/);
        let steps: string[] = [];

        // Bei Zeilen mit mehr als 7 Worten wird davon ausgegangen, dass es sich hierbei um einen
        // Zubereitungsschritt handelt. Sollten mehrfach Zeilen nacheinander kommen, die nicht wie
        // ein Zubereitungsschritt aussehen dann wird davon ausgegangen, dass der entsprechende
        // Abschnitt verlassen wurde und auch keine weiteren Zutaten kommen.
        let previousLineWasStep = 0;
        for (let line of text) {
            if (!line) {
                previousLineWasStep -= 1;
                continue;
            }
            let l = line.split(/\s/);
            if (l.length < 7) {
                previousLineWasStep -= 1;
                continue;
            }
            if (steps.length > 0 && previousLineWasStep === 0) {
                break;
            }
            previousLineWasStep = 2;
            steps.push(line);
        }
        return steps;
    };

    public componentDidUpdate() {
        if (this.state.submitted) {
            this.props.history.push('/view?id=' + this.state.id);
        }
    }

    private submitRecipeFormHandler = (event: MouseEvent) => {
        event.preventDefault();

        let url = 'https://recipes.pyfox.net/php/add.php';

        const recipeData: IRecipe = {
            calories: 0,
            difficulty: 'leicht',
            durationHours: 0,
            durationMinutes: 45,
            image: '',
            title: this.state.title,
            servings: this.state.servings,
            ingredients: this.extractIngredients(),
            instructions: this.extractSteps(),
            recipeID: -1,
        };

        // @ts-ignore
        recipeData.authKey = localStorage.getItem('authKey');
        fetch(url, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(recipeData),
        })
            .then(response => response.text())
            .then(data => {
                this.setState({ submitted: true, id: Number(data) });
            })
            .catch(e => {
                this.setState({ error: e });
            });
    };

    onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ title: event.currentTarget.value });
    };

    onContentChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({ content: event.currentTarget.value });
    };

    onServingsChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({ servings: Number(event.currentTarget.value) });
    };

    public render() {
        return (
            <div className={classes.createRecipeForm}>
                {this.state.error}
                <br />
                <label>Titel</label>
                <input type="text" value={this.state.title} onChange={this.onTitleChange} />
                <label>Portionen</label>
                <input type="number" value={this.state.servings} onChange={this.onServingsChange} />
                <label>Rezept</label>
                <textarea
                    aria-multiline={true}
                    value={this.state.content}
                    onChange={this.onContentChange}
                />
                <h1>Zutaten</h1>
                {this.extractIngredients().map((element, i) => (
                    <div key={i}>
                        <b>{element.name}</b> {element.amount} {element.unit}
                    </div>
                ))}
                <h1>Schritte</h1>
                {this.extractSteps().map((instr, i) => (
                    <div key={i}>
                        {i + 1} {instr}
                    </div>
                ))}
                <Button buttonType="primary" clicked={this.submitRecipeFormHandler}>
                    Speichern
                </Button>
            </div>
        );
    }
}

export default QuickAddPage;
