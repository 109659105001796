import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classes from '../AllRecipesPage/AllRecipesPage.module.css';

import { IRecipeInfo } from '../../shared/interfaces';

import Spinner from '../../components/UI/Spinner/Spinner';
import DeleteHandling from '../../shared/DeleteHandling/DeleteHandling';
import ListEntry from '../AllRecipesPage/ListEntry/ListEntry';
import PageContentSearch from './PageContentSearch/PageContentSearch';

interface IState {
    recipesList: IRecipeInfo[];
    error: boolean;
    searchParams: string;
    itemIdToDelete?: number;
    fetching: boolean;
}

class SearchRecipePage extends Component<RouteComponentProps, IState> {
    public state: IState = {
        recipesList: [],
        error: false,
        searchParams: '',
        itemIdToDelete: undefined,
        fetching: false,
    };

    private getSearchParamTerms = (): string => {
        const query = new URLSearchParams(this.props.location.search);
        return query.get('term') || '';
    };

    public componentDidMount() {
        const searchParams = this.getSearchParamTerms();
        this.fetchRecipes(searchParams);
    }

    public componentDidUpdate() {
        const searchParams = this.getSearchParamTerms();
        if (this.state.searchParams !== searchParams) {
            this.fetchRecipes(searchParams);
        }
    }

    private fetchRecipes = (searchParams: string) => {
        if (!searchParams || this.state.fetching) {
            return;
        }
        this.setState({ fetching: true });
        const searchParamsAsArray = searchParams.split(' ');
        const authKey = localStorage.getItem('authKey');
        fetch('https://recipes.pyfox.net/php/search.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ searchParamsAsArray, authKey }),
        })
            .then(response => response.json())
            .then(data => {
                this.setState({ recipesList: data, searchParams, fetching: false });
            })
            .catch(() => {
                this.setState({ error: true });
            });
    };

    private editRecipeHandler = (id: number) => {
        this.props.history.push('/edit?id=' + id);
    };

    private initDeleteHandler = (id: number) => {
        this.setState({ itemIdToDelete: id });
    };

    private cancelDeleteHandler = () => {
        this.setState({ itemIdToDelete: undefined });
    };

    private deletedRecipeHandler = () => {
        this.setState({ itemIdToDelete: undefined });
        const searchParams = this.getSearchParamTerms();
        this.fetchRecipes(searchParams);
    };

    public render() {
        let recipeList;

        if (this.state.recipesList.length === 0) {
            recipeList = (
                <tr>
                    <td style={{ width: '100%', justifyContent: 'flex-start' }}>
                        Keine passenden Einträge
                    </td>
                </tr>
            );
        } else {
            // Generiert GUI-Komponenten aus der Datenbankrückgabe
            const recipesArray: IRecipeInfo[] = [];
            for (const key in this.state.recipesList) {
                recipesArray.push(this.state.recipesList[key]);
            }

            recipeList = recipesArray.map(recipe => (
                <ListEntry
                    key={recipe.recipeID}
                    recipeInfo={recipe}
                    editRecipe={() => this.editRecipeHandler(recipe.recipeID)}
                    deleteRecipe={() => this.initDeleteHandler(recipe.recipeID)}
                />
            ));
        }

        return (
            <div className={classes.allRecipes}>
                <DeleteHandling
                    id={this.state.itemIdToDelete}
                    cancel={this.cancelDeleteHandler}
                    deleted={this.deletedRecipeHandler}
                />
                {!this.state.fetching ? (
                    <PageContentSearch
                        recipeList={recipeList}
                        searchParams={this.getSearchParamTerms()}
                    />
                ) : (
                    <Spinner />
                )}
            </div>
        );
    }
}

export default SearchRecipePage;
