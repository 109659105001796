import React, { Fragment } from 'react';
import classes from '../ViewRecipePage.module.css';

import { IIngredient } from '../../../shared/interfaces';

interface IProps {
    ingredients: IIngredient[];
}
/**
 * Generiert GUI-Komponente Zutatenliste aus den erhaltenen Datenbank-Objekten
 */
const IngredientList = (props: IProps) => {
    const ingredientsObject = props.ingredients;
    const ingredients =
        ingredientsObject.length !== 0 ? (
            ingredientsObject.map((ingredient: IIngredient, index: number) => (
                <li key={index}>
                    <span className={classes.amount}>
                        {' '}
                        {ingredient.amount} {ingredient.unit}
                    </span>
                    {ingredient.name}
                </li>
            ))
        ) : (
            <li>-</li>
        );

    return <Fragment>{ingredients}</Fragment>;
};

export default IngredientList;
