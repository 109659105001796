import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../../components/UI/Button/Button';
import placeholderImage from '../../../img/placeholder-image.png';
import { IRecipeInfo } from '../../../shared/interfaces';

interface IProps {
    recipeInfo: IRecipeInfo;
    editRecipe: () => void;
    deleteRecipe: () => void;
}

/**
 * Listeneintrag für ein Rezept auf der ALlRecipesPage
 * (Übersicht über alle Rezepte)
 */
const ListEntry = (props: IProps) => (
    <tr>
        <td>
            <img
                src={props.recipeInfo.image ? props.recipeInfo.image : placeholderImage}
                alt={props.recipeInfo.title}
            />
        </td>
        <td>
            <Link to={'/view?id=' + props.recipeInfo.recipeID}>
                <strong>{props.recipeInfo.title}</strong>
            </Link>
        </td>
        <td>
            <Button buttonType="link" clicked={props.editRecipe}>
                Bearbeiten
            </Button>
            <Button buttonType="link-secondary" clicked={props.deleteRecipe}>
                Löschen
            </Button>
        </td>
    </tr>
);

export default ListEntry;
