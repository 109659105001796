import React, { ChangeEvent, Fragment } from 'react';

import InstructionInput from './InstructionInput/InstructionInput';

interface IProps {
    instructionFieldsCounter: number;
    instructions: string[];
    changeInstruction: (event: ChangeEvent<HTMLTextAreaElement>, instrId: number) => void;
}
/**
 * Generiert Input-Gruppe für die Zubereitungsschritte
 */
const InstructionInputs = (props: IProps) => {
    const instructionInputsArray = [];

    for (let i = 0; i < props.instructionFieldsCounter; i++) {
        instructionInputsArray.push(
            <InstructionInput
                instrId={i}
                key={i}
                changeInstruction={props.changeInstruction}
                instructions={props.instructions}
            />,
        );
    }

    return <Fragment>{instructionInputsArray}</Fragment>;
};

export default InstructionInputs;
