import React, { Component } from 'react';
import classes from '../../shared/CreateRecipeForm/CreateRecipeForm.module.css';

import CreateRecipeForm from '../../shared/CreateRecipeForm/CreateRecipeForm';

class CreateRecipePage extends Component {
    public render() {
        return (
            <div className={classes.createRecipeForm}>
                <h2>Neues Rezept erstellen</h2>
                <CreateRecipeForm mode="create" />
            </div>
        );
    }
}

export default CreateRecipePage;
