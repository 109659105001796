import React, { Fragment } from 'react';

interface IProps {
    instructions: string[];
}

/**
 * Generiert GUI-Komponente Zubereitungs-Schritte aus den erhaltenen Datenbank-Objekten
 */
const InstructionList = (props: IProps) => {
    const instructionsObject = props.instructions;
    const instructions =
        instructionsObject.length !== 0 ? (
            instructionsObject.map((instruction, index) => <li key={index}>{instruction}</li>)
        ) : (
            <li>-</li>
        );

    return <Fragment>{instructions}</Fragment>;
};

export default InstructionList;
