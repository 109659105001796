import React, {ChangeEvent, Component} from 'react';
import { RouteComponentProps } from 'react-router-dom';
import classes from '../../shared/CreateRecipeForm/CreateRecipeForm.module.css';
import Button from "../../components/UI/Button/Button";
import {generateHash} from "../../shared/helpers";

const defaultState = {
    mail: '',
    password: '',
    authKey: '',
};

class LoginPage extends Component<RouteComponentProps, typeof defaultState> {

    public readonly state: typeof defaultState = defaultState;

    onMailChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({mail: event.currentTarget.value});
    };

    onPassChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({password: event.currentTarget.value});
    };

    onSaveBtnClicked = () => {
        const authKey = generateHash(this.state.mail + this.state.password);
        localStorage.setItem('authKey', authKey);
        this.setState({authKey: authKey})
    };

    public render() {
        return (
            <div className={classes.createRecipeForm}>
                {this.state.authKey}<br />
                <label>Mail</label>
                <input type='text' value={this.state.mail} onChange={this.onMailChange} />
                <label>Passwort</label>
                <input type='password' value={this.state.password} onChange={this.onPassChange} />
                <Button buttonType='primary' clicked={this.onSaveBtnClicked}>Speichern</Button>
            </div>
        );
    }
}

export default LoginPage;
