import React, { ChangeEvent, Fragment, ReactNode } from 'react';

import { IIngredient } from '../../interfaces';

import IngredientInput from './IngredientInput/IngredientInput';

interface IProps {
    ingredientFieldsCounter: number;
    ingredients: IIngredient[];
    changeIngredient: (
        event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
        ingId: number,
    ) => void;
}
/**
 * Generiert Input-Gruppe für die Zutaten
 */
const IngredientInputs = (props: IProps) => {
    const ingredientInputsArray: ReactNode[] = [];

    for (let i = 0; i < props.ingredientFieldsCounter; i++) {
        ingredientInputsArray.push(
            <IngredientInput
                ingId={i}
                key={i}
                changeIngredient={props.changeIngredient}
                ingredient={props.ingredients[i]}
            />,
        );
    }
    return <Fragment>{ingredientInputsArray}</Fragment>;
};

export default IngredientInputs;
