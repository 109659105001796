import React, { ChangeEvent } from 'react';
import classes from '../CreateRecipeForm.module.css';

interface IProps {
    durationHours: number | null;
    durationMinutes: number | null;
    calories: number | null;
    difficulty: 'leicht' | 'mittel' | 'schwer';
    changeInput: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void;
}
/**
 * Generiert Input-Felder für die Zubereitungshinweise
 * (Zubereitungszeit in h und min, Kalorien, Schwierigkeit)
 */
const PreparationInfos = (props: IProps) => (
    <div className={classes.fieldset}>
        <div className={classes.prepTimeContainer}>
            <label>Zubereitungszeit</label>
            <div className={classes.wrapper}>
                <div className={[classes.field, classes.durationHours].join(' ')}>
                    <div className={classes.prepTime}>
                        <input
                            id={'durationHours'}
                            type="number"
                            value={props.durationHours === null ? '' : props.durationHours}
                            onChange={event => props.changeInput(event)}
                        />
                        <p className={classes.time}>h</p>
                    </div>
                </div>
                <div className={[classes.field, classes.durationMinutes].join(' ')}>
                    <div className={classes.prepTime}>
                        <input
                            id={'durationMinutes'}
                            type="number"
                            value={props.durationMinutes === null ? '' : props.durationMinutes}
                            onChange={event => props.changeInput(event)}
                        />
                        <p className={classes.time}>min</p>
                    </div>
                </div>
            </div>
        </div>
        <div className={[classes.field, classes.difficulty].join(' ')}>
            <label>Schwierigkeitsgrad</label>
            <select
                id={'difficulty'}
                value={props.difficulty}
                onChange={event => props.changeInput(event)}
            >
                <option value="leicht">leicht</option>
                <option value="mittel">mittel</option>
                <option value="schwer">schwer</option>
            </select>
        </div>
        <div className={[classes.field, classes.calories].join(' ')}>
            <label>Kalorien pro Portion</label>
            <input
                id={'calories'}
                type="number"
                value={props.calories === null ? '' : props.calories}
                onChange={event => props.changeInput(event)}
            />
        </div>
    </div>
);

export default PreparationInfos;
