import React, { ReactNode, Fragment } from 'react';
import classes from '../AllRecipesPage.module.css';

interface IProps {
    recipeList: ReactNode[];
}
const PageContentAll = (props: IProps) => (
    <Fragment>
        <h1>Alle Rezepte</h1>
        <table className={classes.recipeListTable}>
            <tbody>{props.recipeList}</tbody>
        </table>
    </Fragment>
);

export default PageContentAll;
